import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import "react-big-calendar/lib/css/react-big-calendar.css";
require("moment/locale/es-mx")
const localizer = momentLocalizer(moment);











const MyCalendar = ({ eventos, fecha_default, Folio }) => {

    const eventPropGetter =
        (event, start, end, isSelected) => ({
            ...(event.folio.includes(Folio) && {
                style: {
                    backgroundColor: event.color,
                    fontSize: 14,
                    fontColor: 'white',
                    borderStyle: "solid",
                    borderColor: 'yellow',
                }
            }),
            ...(!isSelected && !event.folio.includes(Folio) && {
                style: {
                    backgroundColor: event.color,
                    fontSize: 10,
                },



            }),
            ...(event.status.includes('A') && {
                style: {
                    backgroundColor: event.color,
                    fontSize: 10,
                    fontColor: 'white',
                    borderStyle: "solid",
                    borderColor: 'red',
                    textDecoration: 'line-through'
                },



            }),
            ...(event.status.includes('S') && !event.folio.includes(Folio) && {
                style: {
                    backgroundColor: event.color,
                    fontSize: 10,
                    fontColor: 'white',
                    borderStyle: "solid",
                    borderColor: 'blue',
                },



            }),
        })




    const handleSelectEvent = (e) => {

        var estatus = ''
        var icono = ''
        if (e.status === 'S') {
            estatus = "Solicitado"
            icono = "fa-solid fa-circle-pause"
        }
        if (e.status === 'A') {
            estatus = "Aprobado"
            icono = "fa-solid fa-circle-check"
        }

        toast((t) => (
            <div className='btn-light btn-lg  w-100 p-0 m-0 text-xlg' onClick={() => toast.dismiss(t.id)}>
                <div className="row text-dark w-100 p-0 m-0">
                    <div className="col-12 text-left w-100 p-0 m-0 ">  {e.title}</div>
                    <div className="col-12 text-left w-100 p-0 m-0 text-lg">  Folio: {e.folio}</div>
                    <div className="col-12 w-100 p-0 m-0 text-lg">  Inicio: {moment(e.start).format('L')}</div>
                    <div className="col-12 w-100 p-0 m-0 text-lg">  Fin: {moment(e.end).format('L')}</div>
                    <div className="col-12 w-100 p-0 m-0 text-lg">  Estatus: {estatus} <i class={icono}></i></div>
                </div>
            </div >
        ));


    };

    return (
        <div className="p-0 m-0 " style={{ height: "auto" }}>
            <Toaster
                containerStyle={{
                    position: "fixed",
                    top: "40%",
                    padding: 0,
                    margin: 0,
                }}
            />









            <Calendar
                defaultDate={fecha_default}
                defaultView="month"
                events={eventos}
                localizer={localizer}
                eventPropGetter={eventPropGetter}
                onSelectEvent={handleSelectEvent}
                onView={handleSelectEvent}
                resizable
                startAccessor="start"
                endAccessor="end"
                style={{ height: "70vh", width: "auto" }}
                messages={{
                    next: ">>",
                    previous: "<<",
                    today: "Hoy",
                    month: "Mes",
                    week: "Semana",
                    day: "Día",
                }}
                showAllEvents={true}
                views={['month']}

            />
        </div>
    );

}

export default MyCalendar;