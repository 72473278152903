import React, { useReducer } from "react";
import EmpReducer from "./EmpReducer";
import EmpContext from "./EmpContext";
import axios from "axios";
import {
  GET_EMP,
  GET_SOL,
  GET_SOLP,
  GET_DDIS,
  GET_DSOL,
  POST_NSOL,
  GET_DFES,
  GET_CONT,
  POST_CONT,
  PUT_ESOL,
  GET_COLS,
  GET_EVEN,
  GET_INFOS,
  POST_PERS,
  COLOR_PERS,
  DIRECTORIO,
  ZONAS,
  CAMPUS,
} from "../types";

const EmpState = (props) => {
  const initialState = {
    empleado: [],
    solicitudes: [],
    dias_disponibles: 0,
    dias_en_proceso: 0,
    res_nsol: 2,
    res_esol: 2,
    dias_festivos: [],
    contrato: [],
    sol_personal: [],
    colaboradores: [],
    eventos: [],
    res_gcont: 0,
    res_gper: 0,
    res_color: 0,
    directorio: [],
    zonas: [],
    campus: [],
  };
  const [state, dispatch] = useReducer(EmpReducer, initialState);

  const getEmp = async (correo) => {
    try {
      const res = await axios.post(
        "https://appsch.redaliat.mx/API/index.php/auth/auth",
        { EMAIL: correo }
      );

      const data = res.data;

      dispatch({
        type: GET_EMP,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getSol = async (emplid) => {
    try {
      const res = await axios.get(
        "https://appsch.redaliat.mx/API/index.php/solicitudes/lista?emplid=" +
          emplid
      );
      const data = res.data;

      dispatch({
        type: GET_SOL,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const admSol = async (emplid, motivo, folio, estatus) => {
    try {
      const res = await axios.put(
        "https://appsch.redaliat.mx/API/index.php/solicitudes/admin",
        {
          EMPLID_AUTHOR: emplid,
          FOLIO: folio,
          MOTIVO: motivo,
          STATUS: estatus,
        }
      );
      const data = res.data["respuesta"];
      dispatch({
        type: PUT_ESOL,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const setEsol = () => {
    dispatch({
      type: PUT_ESOL,
      payload: 2,
    });
  };

  const getdias_en_proceso = async (emplid) => {
    try {
      const res = await axios.get(
        "https://appsch.redaliat.mx/API/index.php/solicitudes/solicitados?emplid=" +
          emplid
      );

      const data = res.data[0].solicitados;
      dispatch({
        type: GET_DSOL,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getdias_disponibles = async (emplid) => {
    try {
      const res = await axios.get(
        "https://appsch.redaliat.mx/API/index.php/param/diasdisponibles?emplid=" +
          emplid
      );

      const data = res.data[0].DISPONIBLES;

      dispatch({
        type: GET_DDIS,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getdias_festivos = async (ano) => {
    try {
      const res = await axios.get(
        "https://appsch.redaliat.mx/API/index.php/param/asueto?ano=" + ano
      );

      const data = res.data;

      var arreglo = [];
      data.forEach((element) => {
        arreglo.push(element["FECHA"]);
      });

      dispatch({
        type: GET_DFES,
        payload: arreglo,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const get_contrato = async (emplid) => {
    try {
      const res = await axios.get(
        "https://appsch.redaliat.mx/API/index.php/contrato/info?emplid=" +
          emplid
      );
      const data = res.data;
      dispatch({
        type: GET_CONT,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const get_eventos = async (emplid) => {
    try {
      const res = await axios.get(
        "https://appsch.redaliat.mx/API/index.php/cols/eventos?emplid=" + emplid
      );
      const data = res.data;
      dispatch({
        type: GET_EVEN,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const get_colaboradores = async (emplid) => {
    try {
      const res = await axios.get(
        "https://appsch.redaliat.mx/API/index.php/cols/lista?emplid=" + emplid
      );
      const data = res.data;

      dispatch({
        type: GET_COLS,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const get_sol_personal = async (emplid) => {
    try {
      const res = await axios.get(
        "https://appsch.redaliat.mx/API/index.php/solicitudes/solper?emplid=" +
          emplid
      );
      const data = res.data;
      dispatch({
        type: GET_SOLP,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const g_empcont = async (datos) => {
    try {
      const res = await axios.post(
        "https://appsch.redaliat.mx/API/index.php/contrato/add",
        datos
      );

      const data = res.data.respuesta;
      dispatch({
        type: POST_CONT,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const g_color = async (datos) => {
    try {
      const res = await axios.put(
        "https://appsch.redaliat.mx/API/index.php/persona/color",
        datos
      );

      const data = res.data.respuesta;
      dispatch({
        type: COLOR_PERS,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const g_persona = async (datos) => {
    try {
      const res = await axios.post(
        "https://appsch.redaliat.mx/API/index.php/persona/add",
        datos
      );

      const data = res.data.respuesta;
      dispatch({
        type: POST_PERS,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const g_directorio = async (campus) => {
    try {
      const res = await axios.get(
        "https://appsch.redaliat.mx/API/index.php/cols/directorio?campus=" +
          campus
      );

      const data = res.data;

      dispatch({
        type: DIRECTORIO,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const g_zonas = async () => {
    try {
      const res = await axios.get(
        "https://appsch.redaliat.mx/API/index.php/cols/zonas"
      );

      const data = res.data;

      dispatch({
        type: ZONAS,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const g_campus = async (zona) => {
    try {
      const res = await axios.get(
        "https://appsch.redaliat.mx/API/index.php/cols/campus?zona=" + zona
      );

      const data = res.data;

      dispatch({
        type: CAMPUS,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const info_sol = async (folio) => {
    try {
      const res = await axios.get(
        "https://appsch.redaliat.mx/API/index.php/solicitudes/info?folio=" +
          folio
      );
      const data = res.data;
      dispatch({
        type: GET_INFOS,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const NuevaSolicitud = async ({
    empleado,
    f_i,
    f_f,
    regresa,
    solicitados,
    comentario,
  }) => {
    try {
      const res = await axios.post(
        "https://appsch.redaliat.mx/API/index.php/solicitudes/nueva",
        {
          EMPLID: empleado.EMPLID,
          EMP_RCD: empleado.EMP_RCD,
          FECHA_INICIAL: f_i,
          FECHA_FINAL: f_f,
          FECHA_REANUDA: regresa,
          DIAS_SOLICITADOS: solicitados,
          COMENTARIO: comentario,
          LAST_STATUS: "S",
        }
      );

      const data = res.data.respuesta;

      dispatch({
        type: POST_NSOL,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <EmpContext.Provider
      value={{
        empleado: state.empleado,
        solicitudes: state.solicitudes,
        dias_disponibles: state.dias_disponibles,
        dias_en_proceso: state.dias_en_proceso,
        res_nsol: state.res_nsol,
        dias_festivos: state.dias_festivos,
        contrato: state.contrato,
        res_gcont: state.res_gcont,
        res_esol: state.res_esol,
        sol_personal: state.sol_personal,
        colaboradores: state.colaboradores,
        eventos: state.eventos,
        insolicitud: state.insolicitud,
        res_gper: state.res_gper,
        res_color: state.res_color,
        directorio: state.directorio,
        zonas: state.zonas,
        campus: state.campus,
        g_color,
        getEmp,
        getSol,
        g_persona,
        get_sol_personal,
        getdias_disponibles,
        getdias_en_proceso,
        NuevaSolicitud,
        getdias_festivos,
        get_contrato,
        g_empcont,
        admSol,
        setEsol,
        get_colaboradores,
        get_eventos,
        info_sol,
        g_directorio,
        g_zonas,
        g_campus,
      }}
    >
      {props.children}
    </EmpContext.Provider>
  );
};

export default EmpState;
