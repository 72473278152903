import React, { useState, useEffect } from "react";
import { Container, Form, FormCheck, Pagination, Table } from "react-bootstrap";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";
import toast from "react-hot-toast";
import { HexColorPicker } from "react-colorful";

import {
    Modal,
    Button,
    Row,
    Col,
    FormControl,
    FloatingLabel,
} from "react-bootstrap";
import { useSSRSafeId } from "@restart/ui/esm/ssr";


const AdminColaboradores = ({ empleado, get_colaboradores, colaboradores, eventos, get_eventos, g_color, res_color }) => {

    const [pagina, setPagina] = useState(1);
    const [ccolor, setCcolor] = useState(false)
    const [persona, setpersona] = useState("")
    const [color, setColor] = useState("#aabbcc");
    useEffect(() => {
        get_colaboradores(empleado.EMPLID);
        get_eventos('all--' + empleado.EMPLID);
    }, [empleado])



    const Paginar = (datos, pagina) => {
        return datos.slice((pagina - 1) * 5, pagina * 5);
    };

    const Paginamax = (datos) => {
        let pmax = Math.floor(datos.length / 5);
        let resto = datos.length % 5;

        if (resto > 0) {
            pmax = pmax + 1;
        }

        return pmax;
    };

    const cambiarcolor = () => {
        g_color({ EMPLID: persona, COLOR: color })
        setCcolor(!ccolor)
    };
    console.log(res_color)
    useEffect(() => {
        get_colaboradores(empleado.EMPLID);
        get_eventos('all--' + empleado.EMPLID);

    }, [res_color])




    return (
        <>

            <Container className='w-100  border  p-0 m-0 ' style={{ height: "40vh" }} >
                <div className="text-center row p-0 m-0">
                    <div className="col-12 p-0 m-0">
                        <h3> Configuración de colores</h3>
                    </div>



                </div>

                < Table bordered >
                    <thead>
                        <tr>
                            <th className="col-3 m-0 p-0">N.Empleado</th>
                            <th className="col-7 m-0 p-0">Nombre</th>
                            <th className="col-1 m-0 p-0">Ver</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Paginar(colaboradores, pagina).map(
                            ({
                                EMPLID,
                                NOMBRE,
                                APATERNO,
                                AMATERNO,
                                COLOR
                            }) => {
                                return (
                                    <tr
                                        key={EMPLID}
                                    >
                                        <td>{EMPLID}</td>
                                        <td>{NOMBRE + " " + APATERNO + " " + AMATERNO}</td>

                                        <td>
                                            <button className="rounded-circle" style={{ background: COLOR }} onClick={() => {
                                                setCcolor(!ccolor)
                                                setpersona(EMPLID)
                                            }}
                                            ><i class="fa-solid fa-circle-notch"></i></button>


                                        </td>
                                    </tr>
                                );
                            }
                        )}
                    </tbody>
                </Table >
            </Container>
            <Pagination>
                <Pagination.First onClick={() => setPagina(1)} />
                <Pagination.Prev
                    onClick={() => setPagina(pagina > 1 ? pagina - 1 : 1)}
                />

                <Pagination.Item active>{pagina}</Pagination.Item>

                <Pagination.Next
                    onClick={() =>
                        setPagina(pagina < Paginamax(colaboradores) ? pagina + 1 : Paginamax(colaboradores))
                    }
                />
                <Pagination.Last
                    onClick={() => {
                        setPagina(Paginamax(colaboradores));
                    }}
                />
            </Pagination>


            <Modal centered size='sm' show={ccolor} onHide={() => setCcolor(!ccolor)}>
                <Modal.Body>
                    <HexColorPicker className="w-100" color={color} onChange={setColor} />
                    <button className="btn-sm w-50 rounded" onClick={() => cambiarcolor()}>Guardar</button>
                    <button className="btn-sm w-50 rounded" onClick={() => setCcolor(!ccolor)}>Cerrar</button>
                </Modal.Body>
            </Modal>


        </>
    );

};

export default AdminColaboradores;