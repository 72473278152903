import {
  GET_EMP,
  GET_SOL,
  GET_SOLP,
  GET_DSOL,
  GET_DDIS,
  POST_NSOL,
  GET_DFES,
  GET_CONT,
  POST_CONT,
  PUT_ESOL,
  SET_ESOL,
  GET_COLS,
  GET_EVEN,
  GET_INFOS,
  POST_PERS,
  COLOR_PERS,
  DIRECTORIO,
  ZONAS,
  CAMPUS,
} from "../types";

export default (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_EMP:
      return {
        ...state,
        empleado: payload,
      };

    case GET_SOL:
      return {
        ...state,
        solicitudes: payload,
      };
    case GET_SOLP:
      return {
        ...state,
        sol_personal: payload,
      };
    case GET_DDIS:
      return {
        ...state,
        dias_disponibles: payload,
      };
    case GET_DSOL:
      return {
        ...state,
        dias_en_proceso: payload,
      };
    case GET_DFES:
      return {
        ...state,
        dias_festivos: payload,
      };
    case POST_NSOL:
      return {
        ...state,
        res_nsol: payload,
      };
    case GET_CONT:
      return {
        ...state,
        contrato: payload,
      };
    case POST_CONT:
      return {
        ...state,
        res_gcont: payload,
      };
    case PUT_ESOL:
      return {
        ...state,
        res_esol: payload,
      };
    case SET_ESOL:
      return {
        ...state,
        res_esol: payload,
      };

    case GET_COLS:
      return {
        ...state,
        colaboradores: payload,
      };
    case GET_EVEN:
      return {
        ...state,
        eventos: payload,
      };
    case GET_INFOS:
      return {
        ...state,
        insolicitud: payload,
      };

    case POST_PERS:
      return {
        ...state,
        res_gper: payload,
      };
    case COLOR_PERS:
      return {
        ...state,
        res_color: payload,
      };

    case DIRECTORIO:
      return {
        ...state,
        directorio: payload,
      };

    case ZONAS:
      return {
        ...state,
        zonas: payload,
      };

    case CAMPUS:
      return {
        ...state,
        campus: payload,
      };

    default:
      return {
        state,
      };
  }
};
