import React, { useState, useEffect, useMemo } from "react";
import { Pagination } from "react-bootstrap";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";

import {
    Modal,
    Button,
    Row,
    Col,
    FormControl,
    FloatingLabel,
} from "react-bootstrap";

import MyCalendar from "./MyCalendar";
import AdminColaboradores from "./AdminColaboradores";

const AdminSolicitudes = ({ empleado, get_sol_personal, sol_personal, get_eventos, eventos, get_colaboradores, colaboradores, g_color, res_color, setEsol, admSol, res_esol }) => {

    const [pagina, setPagina] = useState(1);
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [scalen, setscalen] = useState(false);
    const [defecha, setdefecha] = useState(moment().toDate())
    const [fmes, setfmes] = useState(moment(moment().toDate()).format('MMMM'))
    const [sconfig, setSconfig] = useState(false)
    const handleClose = () => setShow(false);
    const handleClose1 = () => setShow1(false);
    const [Folio, setFolio] = useState(0);
    const [status, setStatus] = useState(0);
    const [comentario, setComentario] = useState(0);
    const [fecha_inicial, setFecha_inicial] = useState(0);
    const [fecha_final, setFecha_final] = useState(0);
    const [fecha_reanuda, setFecha_reanuda] = useState(0);
    const [fecha_solicitud, setFecha_solicitud] = useState(0);
    const [dias_solicitados, setDias_solicitados] = useState(0);

    const [nombres, setNombres] = useState(0);
    const [apellido_paterno, setApellido_paterno] = useState(0);
    const [apellido_materno, setApellido_materno] = useState(0);
    const [motivo, setMotivo] = useState("");

    const Confirmar = () => {
        setMotivo('')
        setShow(false);
        setShow1(true);


    };

    useEffect(() => {
        if (res_esol !== 2) {

            if (res_esol === 1) {

                toast((t) => (

                    <div className=' w-100 p-0 m-0 rounded ' onClick={() => toast.dismiss(t.id)} >
                        <i class="fa-solid fa-circle-check"></i> Solicitud Procesada.
                    </div>

                )
                    ,

                    {

                        duration: 1000,

                        position: 'top-center',
                        className: 'p-0 m-0 text-lg',
                        style: {
                            borderRadius: '5px',
                            background: '#00ae2fe7',
                            color: '#fff',
                            padding: 0,
                            margin: 0,
                        },
                    }

                )


                setShow1(false);
                get_sol_personal(empleado.EMPLID);
                setEsol()
            }
            if (res_esol === 0) {
                toast((t) => (

                    <div className=' w-100 p-0 m-0 rounded ' onClick={() => toast.dismiss(t.id)} >
                        <i class="fa-solid fa-circle-exclamation"></i> Error al procesar.
                    </div>

                )
                    ,

                    {

                        duration: 1000,

                        position: 'top-center',
                        className: 'p-0 m-0 text-lg',
                        style: {
                            borderRadius: '5px',
                            background: '#f61e1ee7',
                            color: '#fff',
                            padding: 0,
                            margin: 0,
                        },
                    }
                )
                setShow1(false);
                setEsol()
            }

        }

    }, [res_esol])
    useEffect(() => {
        get_sol_personal(empleado.EMPLID);

    }, [])

    const vacalendario = (elfolio, lafecha) => {
        console.log(lafecha);
        get_eventos('all--' + empleado.EMPLID);
        setdefecha(lafecha)
        setscalen(true)
        setFolio(elfolio)
    }

    const vucalendario = (colaborador, lafecha) => {
        console.log(lafecha);
        get_eventos('one--' + colaborador);
        setdefecha(lafecha)
        setscalen(true)
        setfmes(moment(lafecha).format('MMMM').toUpperCase())
        setFolio(colaborador)
    }

    const Cancelar = (estatus) => {
        setShow1(false);
        setShow(false);
        if (estatus === 'A') {
            estatus = "Aprobada"


            toast((t) => (

                <div className='row w-100 p-0 m-0 border border-dark rounded bg-white' >
                    <div className="col-12 titulo-md text-dark w-100 p-0 m-0  rounded">
                        Confirmación
                    </div>
                    <div className="col-12 text-dark w-100 p-0 m-0">

                        <div className="table-responsive">
                            <table className="table p-0 m-0 text-lgb">
                                <tbody className=" p-0 m-0">
                                    <tr className=" p-0 m-0">
                                        <td className=" p-0 m-0" >Folio</td>
                                        <td className=" p-0 m-0 ">{Folio}</td>
                                    </tr>
                                    <tr className=" p-0 m-0">
                                        <td className=" p-0 m-0">Fecha inicial:</td>
                                        <td className=" p-0 m-0">{moment(fecha_inicial).format("LL")}</td>
                                    </tr>
                                    <tr className=" p-0 m-0">
                                        <td className=" p-0 m-0">Fecha final:</td>
                                        <td className=" p-0 m-0">{moment(fecha_final).format("LL")}</td>
                                    </tr>
                                    <tr className=" p-0 m-0">
                                        <td className=" p-0 m-0">Estatus:</td>
                                        <td className=" p-0 m-0">{estatus}</td>
                                    </tr>



                                </tbody>
                            </table>
                        </div>


                    </div>
                    <div className="col-12 row text-dark w-100 p-1 m-0">
                        <div className="col-6"><button className="w-100 rounded btn-success" onClick={() => { Procesar("R", t) }}>Confirmar <i class="fa-solid fa-check-double"></i></button></div>
                        <div className="col-6"><button className="w-100 rounded btn-danger" onClick={() => toast.dismiss(t.id)}>Cerrar <i class="fa-solid fa-arrow-right-from-bracket"></i></button></div>
                    </div>


                </div >
            )

                ,

                {

                    duration: 20000,
                    position: 'top-center',
                    className: 'p-0 m-0',
                    style: {
                        borderRadius: '5px',
                        background: 'transparent',
                        color: '#fff',
                        padding: 0,
                        margin: 0,
                    },
                }
            )





        }


        if (estatus === 'R') {
            estatus = "Rechazada"

            if (motivo.length < 20) {
                toast((t) => (

                    <div className=' w-100 p-0 m-0 rounded ' >
                        <i class="fa-solid fa-triangle-exclamation"></i> Mínimo 20 caracteres.
                    </div>

                )
                    ,

                    {

                        duration: 1000,

                        position: 'top-center',
                        className: 'p-0 m-0 text-lg',
                        style: {
                            borderRadius: '5px',
                            background: '#f61e1ee7',
                            color: '#fff',
                            padding: 0,
                            margin: 0,
                        },
                    }
                )



                return
            }
            toast((t) => (

                <div className='row w-100 p-0 m-0 border border-dark rounded bg-white' >
                    <div className="col-12 titulo-md text-dark w-100 p-0 m-0  rounded">
                        Confirmación
                    </div>
                    <div className="col-12 text-dark w-100 p-0 m-0">

                        <div className="table-responsive">
                            <table className="table p-0 m-0 text-lgb">
                                <tbody className=" p-0 m-0">
                                    <tr className=" p-0 m-0">
                                        <td className=" p-0 m-0" >Folio</td>
                                        <td className=" p-0 m-0 ">{Folio}</td>
                                    </tr>
                                    <tr className=" p-0 m-0">
                                        <td className=" p-0 m-0">Fecha inicial:</td>
                                        <td className=" p-0 m-0">{moment(fecha_inicial).format("LL")}</td>
                                    </tr>
                                    <tr className=" p-0 m-0">
                                        <td className=" p-0 m-0">Fecha final:</td>
                                        <td className=" p-0 m-0">{moment(fecha_final).format("LL")}</td>
                                    </tr>
                                    <tr className=" p-0 m-0">
                                        <td className=" p-0 m-0">Estatus:</td>
                                        <td className=" p-0 m-0">{estatus}</td>
                                    </tr>
                                    <tr className=" p-0 m-0">
                                        <td colspan='2' className=" p-0 m-0">Motivo del Rechazo:</td>

                                    </tr>
                                    <tr>
                                        <td colspan='2' className=" p-0 m-0">

                                            <textarea className="form-control p-0 m-0 text-lgb" rows="5" value={motivo}></textarea>



                                        </td>
                                    </tr>


                                </tbody>
                            </table>
                        </div>


                    </div>
                    <div className="col-12 row text-dark w-100 p-1 m-0">
                        <div className="col-6"><button className="w-100 rounded btn-success" onClick={() => { Procesar("R", t) }}>Confirmar <i class="fa-solid fa-check-double"></i></button></div>
                        <div className="col-6"><button className="w-100 rounded btn-danger" onClick={() => toast.dismiss(t.id)}>Cerrar <i class="fa-solid fa-arrow-right-from-bracket"></i></button></div>
                    </div>


                </div >
            )

                ,

                {

                    duration: 20000,
                    position: 'top-center',
                    className: 'p-0 m-0',
                    style: {
                        borderRadius: '5px',
                        background: 'transparent',
                        color: '#fff',
                        padding: 0,
                        margin: 0,
                    },
                }
            )
        }






        // console.log(Folio, motivo)

    }

    const Procesar = (estatus, t) => {
        toast.dismiss(t.id)
        setEsol()
        admSol(empleado.EMPLID, motivo, Folio, estatus)


    }



    const Paginar = (datos, pagina) => {
        return datos.slice((pagina - 1) * 5, pagina * 5);
    };

    const Paginamax = (datos) => {
        let pmax = Math.floor(datos.length / 5);
        let resto = datos.length % 5;

        if (resto > 0) {
            pmax = pmax + 1;
        }

        return pmax;
    };



    const handleShow = ({
        FOLIO,
        NOMBRE,
        APATERNO,
        AMATERNO,
        FECHA_INICIAL,
        FECHA_FINAL,
        FECHA_REANUDA,
        COMENTARIO,
        FECHA_DE_SOLICITUD,
        STATUS,
        DIAS_SOLICITADOS

    }) => {

        setFolio(FOLIO);
        setNombres(NOMBRE);
        setApellido_paterno(APATERNO);
        setApellido_materno(AMATERNO);
        setFecha_inicial(FECHA_INICIAL);
        setFecha_final(FECHA_FINAL);
        setFecha_reanuda(FECHA_REANUDA)
        setComentario(COMENTARIO);
        setFecha_solicitud(FECHA_DE_SOLICITUD)
        setStatus(STATUS)
        setDias_solicitados(DIAS_SOLICITADOS)
        setShow(true);
    };


    return (
        <>
            <Toaster
                containerStyle={{
                    position: "fixed",
                    top: "40%",
                    padding: 0,
                    margin: 0,
                    background: 'transparent',
                }}
                toastOptions={{
                    success: {
                        duration: 3000,
                        style: {
                            background: "green",
                        },
                    },
                    error: {
                        duration: 3000,
                        style: {
                            background: "red",

                            color: "white",
                        },
                    },
                }}
            />
            <div className="row  p-0 m-0  col-12 justify-content-center" >

                <div className="text-center col-lg-12 col-md-12 col-sm-12 col-12  p-0 m-0"> <h2> Solicitudes Pendientes</h2> </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12  shadow p-0 m-0 " >



                    < div className='row   p-0 m-0' >

                        <div className="row   p-0 m-0  bg-primary text-white">
                            <div className=" m-0 p-0 col-lg-1 col-md-3  border p-0 m-0 d-none d-sm-none d-md-block cabecera ">Folio</div>
                            <div className=" m-0 p-0 col-lg-3 col-md-5  border p-0 m-0 d-none d-sm-none d-md-block cabecera">Nombre</div>
                            <div className=" m-0 p-0 col-lg-2 col-md-1  border p-0 m-0 d-none d-sm-none d-md-block cabecera d-block d-sm-none d-md-none d-lg-block">F.Inicial</div>
                            <div className=" m-0 p-0 col-lg-2 col-md-1  border p-0 m-0 d-none d-sm-none d-md-block cabecera d-block d-sm-none d-md-none d-lg-block">F.Final</div>
                            <div className=" m-0 p-0 col-lg-2 col-md-2  border p-0 m-0 d-none d-sm-none d-md-block cabecera d-block d-sm-none d-md-block d-lg-block">F.Solicitud</div>
                            <div className=" m-0 p-0 col-lg-2 col-md-2  border p-0 m-0 d-none d-sm-none d-md-block cabecera">Administrar</div>


                        </div>

                        <div className="row p-0 m-0 ">
                            {Paginar(sol_personal, pagina).map(
                                ({
                                    FOLIO,
                                    NOMBRE,
                                    APATERNO,
                                    AMATERNO,
                                    FECHA_INICIAL,
                                    FECHA_FINAL,
                                    FECHA_REANUDA,
                                    COMENTARIO,
                                    FECHA_DE_SOLICITUD,
                                    STATUS,
                                    DIAS_SOLICITADOS,
                                    EMPLID
                                }) => {

                                    return (
                                        <div className="row p-0 m-0 border rounded celda" key={FOLIO}>
                                            <div className=" m-0 p-0 col-4  p-0 m-0 d-block d-sm-block d-md-none  ">Folio</div>
                                            <div className=" m-0 p-0 col-8 col-sm-8 col-md-3 col-lg-1  p-0 m-0 text-md" >{FOLIO}</div>
                                            <div className=" m-0 p-0 col-4  p-0 m-0 d-block d-sm-block d-md-none ">Nombre</div>
                                            <div className=" m-0 p-0 col-8 col-sm-8 col-md-5 col-lg-3  p-0 m-0 text-md">{NOMBRE + " " + APATERNO + " " + AMATERNO}</div>
                                            <div className=" m-0 p-0 col-4  p-0 m-0 d-block d-sm-none d-md-none ">F.Inicial</div>
                                            <div className=" m-0 p-0 col-8 col-sm-8 col-md-1 col-lg-2  p-0 m-0 d-block d-sm-none d-md-none d-lg-block  text-md"><div className="  m-0 p-0 col-8 col-sm-8 col-md-1 col-lg-1  p-0 m-0">{moment(FECHA_INICIAL).format("DD/MMMM/yy")}</div></div>
                                            <div className=" m-0 p-0 col-4  p-0 m-0 d-block d-sm-none d-md-none ">F.Final</div>
                                            <div className=" m-0 p-0 col-8 col-sm-8 col-md-1 col-lg-2  p-0 m-0 d-block d-sm-none d-md-none d-lg-block text-md"><div className=" text-center m-0 p-0 col-8 col-sm-8 col-md-1 col-lg-1  p-0 m-0">{moment(FECHA_FINAL).format("DD/MMMM/yy")}</div></div>
                                            <div className=" m-0 p-0 col-4  p-0 m-0 d-block d-sm-none d-md-none ">F.Solicitud</div>
                                            <div className=" m-0 p-0 col-8 col-sm-8 col-md-2 col-lg-2  p-0 m-0 d-block d-sm-none d-md-block d-lg-block text-md"><div className=" text-center m-0 p-0 col-8 col-sm-8 col-md-1 col-lg-1  p-0 m-0">{moment(FECHA_DE_SOLICITUD).format("DD/MMMM/yy")}</div></div>
                                            <div className=" m-0 p-0 col-4  p-0 m-0 d-block d-sm-block d-md-none ">Administrar</div>
                                            <div className=" m-0 p-0 col-8 col-sm-8 col-md-2 col-lg-2  p-0 m-0 text-center"><button className='btn btn-outline-secondary rounded w-100' onClick={() => {
                                                handleShow({
                                                    FOLIO,
                                                    NOMBRE,
                                                    APATERNO,
                                                    AMATERNO,
                                                    FECHA_INICIAL,
                                                    FECHA_FINAL,
                                                    FECHA_REANUDA,
                                                    COMENTARIO,
                                                    FECHA_DE_SOLICITUD,
                                                    STATUS,
                                                    DIAS_SOLICITADOS,
                                                    EMPLID
                                                });
                                                setMotivo('')
                                            }}>
                                                Administrar
                                            </button>

                                            </div>


                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </div >
                </div>

            </div>


            <Pagination>
                <Pagination.First onClick={() => setPagina(1)} />
                <Pagination.Prev
                    onClick={() => setPagina(pagina > 1 ? pagina - 1 : 1)}
                />

                <Pagination.Item active>{pagina}</Pagination.Item>

                <Pagination.Next
                    onClick={() =>
                        setPagina(pagina < Paginamax(sol_personal) ? pagina + 1 : Paginamax(sol_personal))
                    }
                />
                <Pagination.Last
                    onClick={() => {
                        setPagina(Paginamax(sol_personal));
                    }}
                />
            </Pagination>

            <Modal
                className="rounded"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="md"
            >
                <Modal.Header className="p-0 m-0" p-0 m-0>
                    <Modal.Title className="text-center w-100 bg-primary text-white ">
                        <Row p-0 m-0>
                            <Col className="col-10" p-0 m-0>Información de Solicitud</Col>
                            <Col className="col-2" p-0 m-0>
                                <Button
                                    variant="transparent"
                                    onClick={handleClose}
                                    className="float-right"
                                >
                                    <i class="fa-solid fa-x"></i>
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Title>
                </Modal.Header >
                <Modal.Body className="p-0 m-0" p-0 m-0>
                    <Row className="p-0 m-0" p-0 m-0>
                        <Col className="p-1 m-0 col-4 " p-0 m-0>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Folio"
                                className="mb-3"
                            >
                                <FormControl aria-label="Folio" value={Folio} readOnly />
                            </FloatingLabel>
                        </Col>
                        <Col className="p-1 m-0 col-4" p-0 m-0>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Estatus"
                                className="m-0 mb-3  p-0 m-0"
                            >
                                <FormControl
                                    aria-label="Estatus"
                                    value={status}
                                    name="status"
                                    readOnly
                                />
                            </FloatingLabel>
                        </Col>
                        <Col className="p-1 m-0 col-4" p-0 m-0>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="F.Solictud"
                                className="mb-3 "
                            >
                                <FormControl
                                    aria-label="Fecha de Solictud"
                                    value={moment(fecha_solicitud).format("DD/MM/yy")}
                                    type="textl"
                                    readOnly
                                />
                            </FloatingLabel>
                        </Col >
                        <Col className="p-1 m-0 col-10" p-0 m-0>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Nombre"
                                className="mb-3"
                            >
                                <FormControl
                                    aria-label="Nombre"
                                    value={
                                        nombres + " " + apellido_paterno + " " + apellido_materno
                                    }
                                    readOnly
                                />
                            </FloatingLabel>
                        </Col>
                        <Col className="p-1 m-0 col-2 col-sm-2 col-md-2 col-lg-2">
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Días"
                                className="mb-3"
                            >
                                <FormControl
                                    className="text-center"
                                    aria-label="Días"
                                    value={dias_solicitados}
                                    type="number"
                                    readOnly
                                />
                            </FloatingLabel>
                        </Col>

                        <Col className="p-1 m-0 col-4">
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Fecha Inicial"
                                className="mb-3"
                            >
                                <FormControl
                                    aria-label="Fecha Inicial"
                                    value={moment(fecha_inicial).format("DD/MM/yy")}
                                    type="text"
                                    readOnly
                                />
                            </FloatingLabel>
                        </Col>
                        <Col className="p-1 m-0 col-4 ">
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Fecha Final"
                                className="mb-3"
                            >
                                <FormControl
                                    aria-label="Fecha Final"
                                    value={moment(fecha_final).format("DD/MM/yy")}
                                    type="text"
                                    readOnly
                                />
                            </FloatingLabel>
                        </Col>
                        <Col className="p-1 m-0 col-4 ">
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Reanuda"
                                className="mb-3"
                            >
                                <FormControl
                                    aria-label="Fecha Reanuda"
                                    value={moment(fecha_reanuda).format("DD/MM/yy")}
                                    type="text"
                                    readOnly
                                />
                            </FloatingLabel>
                        </Col>

                        <Col className="p-1 m-0 col-12 col-sm-12 col-md-12 col-lg-12">
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Comentario"
                                className="mb-3"
                            >
                                <FormControl
                                    as="textarea"
                                    placeholder="Introduce algun comentario (Opcional)"
                                    style={{ height: "100px" }}
                                    value={comentario}
                                    type="text"
                                    readOnly
                                />
                            </FloatingLabel>
                        </Col>
                        <Col className="p-1 m-0 col-12 col-sm-12 col-md-12 col-lg-12">
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Motivo de Rechazo o Cancelación"
                                className="mb-3"
                                hidden={!motivo && true}
                            >
                                <FormControl
                                    as="textarea"
                                    placeholder="motivos"
                                    style={{ height: "100px" }}
                                    value={motivo}
                                    type="text"
                                    readOnly
                                />
                            </FloatingLabel>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className=" m-0 p-1 row">
                    <div className="col-lg-3 col-md-3 col-sm-6 col-6 m-0 p-1 ">
                        <button className='btn-warning rounded w-100' onClick={() => vacalendario(Folio, fecha_inicial)}>Calendario <i class="fa-solid fa-calendar"></i></button>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-6 m-0 p-1 ">
                        <button className='btn-success rounded w-100' onClick={() => Cancelar("A")}>Autorizar <i class="fa-solid fa-check"></i></button>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-6 m-0 p-1 ">
                        <button className='btn-danger rounded w-100' onClick={() => Confirmar()} >Rechazar <i class="fa-solid fa-x"></i></button>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-6 p-1 m-0">
                        <button className='btn-dark rounded w-100' onClick={handleClose}>Cerrar <i class="fa-solid fa-arrow-right-from-bracket"></i></button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal
                show={show1}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="md"
            >
                <Modal.Header className="p-0 m-0">
                    <Modal.Title className="text-center w-100 bg-dark text-white">
                        Motivo
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className=" pl-3 pr-3 pt-1 pb-1 m-0">
                    <Row>
                        <Col className="p-1 m-0 col-12 col-sm-12 col-md-12 col-lg-12">


                            <textarea className="form-control p-0 m-0" rows="5" value={motivo} onChange={(e) => setMotivo(e.target.value)}></textarea>


                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="m-0 p-0">
                    <div className="row m-0 p-1 w-100">
                        <div className="col-6 m-0 p-1 w-50">
                            <button className='btn-success rounded  w-100' onClick={() => Cancelar("R")} >Continuar <i class="fa-solid fa-check"></i></button>
                        </div>

                        <div className="col-6 m-0 p-1 w-50">
                            <button className='btn-secondary rounded w-100' onClick={handleClose1} >Cerrar <i class="fa-solid fa-arrow-right-from-bracket"></i></button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>


            <Modal centered show={scalen} size="lg" onHide={() => setscalen(!scalen)} p-0 m-0>
                <Modal.Body>
                    <MyCalendar eventos={eventos} fecha_default={defecha} Folio={Folio} />
                </Modal.Body>
                <Modal.Footer>
                    <div className="row w-100 p-0 m-0 justify-content-end">
                        <div className="col-2 col-sm-2 col-md-1 col-lg-1 p-1 m-0">
                            <button className='btn btn-outline-dark rounded w-100' onClick={() => setSconfig(!sconfig)}> <i class="fa-solid fa-gear"></i></button>
                        </div>
                        <div className="col-2 col-sm-2 col-md-1 col-lg-1 p-1 m-0">
                            <button className='btn btn-outline-dark rounded w-100' onClick={() => setscalen(!scalen)}> <i class="fa-solid fa-arrow-right-from-bracket"></i></button>
                        </div>

                    </div>

                </Modal.Footer>
            </Modal>

            <Modal centered border-1 show={sconfig} onHide={() => setSconfig(!sconfig)} className='border-dark'>

                <Modal.Body>
                    <div className="col-12 w-100 p-0 m-0">
                        <AdminColaboradores empleado={empleado} get_colaboradores={get_colaboradores} colaboradores={colaboradores} eventos={eventos} get_eventos={get_eventos} g_color={g_color} res_color={res_color} />
                    </div>
                </Modal.Body>
                <div className="row w-100  p-3 pb-1 m-0 justify-content-end">
                    <div className="col-2 col-sm-2 col-md-1 col-lg-1 p-1 m-0">
                        <Button variant="outline-dark" onClick={() => setSconfig(!sconfig)}>
                            <i class="fa-solid fa-arrow-right-from-bracket"></i>
                        </Button>
                    </div>
                </div>
            </Modal>


        </>
    );

};

export default AdminSolicitudes;