export const GET_EMP = "GET_EMP";
export const GET_SOL = "GET_SOL";
export const GET_DDIS = "GET_DDIS";
export const GET_DSOL = "GET_DSOL";
export const GET_DFES = "GET_DFES";
export const GET_CONT = "GET_CONT";
export const GET_SOLP = "GET_SOLP";
export const POST_NSOL = "POST_NSOL";
export const POST_CONT = "POST_CONT";
export const PUT_ESOL = "PUT_ESOL";
export const SET_ESOL = "SET_ESOL";
export const GET_COLS = "GET_COLS";
export const GET_EVEN = "GET_EVEN";
export const GET_INFOS = "GET_INFOS";
export const POST_PERS = "POST_PERS";
export const COLOR_PERS = "COLOR_PERS";
export const DIRECTORIO = "DIRECTORIO";
export const ZONAS = "ZONAS";
export const CAMPUS = "CAMPUS";
