import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import moment from "moment";



// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: 'white',
        alignContent: 'center'
    },
    section: {
        margin: 10,
        padding: 10,
        border: 0,

    },

    sectiontabla: {
        margintop: 10,
        marginLeft: 40,
        marginRight: 40,
        padding: 0,
        border: 0,
        backgroundColor: '#c3cbd0',
        fontFamily: 'Helvetica-Bold',
    },
    sectiondatos: {
        margintop: 10,
        marginLeft: 40,
        marginRight: 40,
        padding: 0,
        border: 0.5,
        textAlign: 'center',
        backgroundColor: '#ffffff',
        fontSize: 14,
        fontFamily: 'Helvetica',
    },
    titulo: {
        fontFamily: 'Times-Bold',
        textAlign: 'center',
        margin: 2,
        fontSize: 13,
        fontWeight: "bold",
    }
    ,
    titulo1: {
        textAlign: 'center',
        margin: 2,
        fontSize: 12,

    }
    ,
    titulo2: {
        textAlign: 'left',
        margin: 2,
        fontSize: 12,
        paddingLeft: 30,

    }
    ,
    tabla: {
        flexDirection: 'row',

    }
    ,
    celda: {
        margin: 0,
        border: 1,

        fontSize: 12,

        width: '20%',
        position: 'relative',
    }
    ,
    table: {
        width: '70%',
        align: 'center'

    },
    row: {
        flexDirection: 'row',

    },
    header: {

        padding: 0,
        width: '95%',
        textAlign: 'center',
        backgroundColor: '#c3cbd0'
    },
    bold: {
        fontWeight: 'bold',

    },
    // So Declarative and unDRY 👌
    row1: {
        width: '25%',
        fontSize: 10,
        padding: 4,
        border: 0.5,
        textAlign: 'left'
    },
    row2: {
        fontSize: 10,
        width: '25%',
        padding: 4,
        borderBottom: 0.5,
        borderTop: 0.5,
        textAlign: 'left'
    },
    row3: {
        fontSize: 10,
        width: '25%',
        borderBottom: 0.5,
        borderTop: 0.5,
        borderRight: 0.5,
        padding: 4,
        textAlign: 'left'
    },
    row4: {
        width: '35%',
        fontSize: 10,
        border: 0.5,
        padding: 4,
        textAlign: 'left'
    },
    row5: {
        width: '35%',
        fontSize: 10,
        border: 0.5,
        padding: 4,
        textAlign: 'left'
    },
    row6: {
        width: '30%',
        fontSize: 10,
        border: 0.5,
        padding: 4,
        textAlign: 'left'
    },
    row7: {
        width: '70%',
        fontSize: 10,
        border: 0.5,
        padding: 4,
        textAlign: 'left'
    },
    row8: {
        width: '30%',
        fontSize: 10,
        border: 0.5,
        padding: 4,
        textAlign: 'left'
    },
    image: {
        width: "50%",
        padding: 20,
        paddingBottom: 0,
    },
    centerImage: {
        alignItems: "left",
    },

});


const FOCH11 = ({ EMPLID, FECHA_INICIAL, FECHA_FINAL, APATERNO, AMATERNO, NOMBRE, CAMPUS, DEPARTAMENTO, FECHA_DE_SOLICITUD, FECHA_REANUDA }) => {


    return (

        < Document >
            <Page size="A4" style={styles.page}>
                <View style={styles.centerImage}>
                    <Image style={styles.image} src="/logo-aliat.png" />
                </View>
                <View style={styles.section}  >
                    <Text style={styles.titulo} >Solicitud de vacaciones</Text>
                    <Text style={styles.titulo1} >Dirección Institucional de Capital Humano</Text>
                    <Text style={styles.titulo1} >FO-CH-11</Text>
                </View>

                <View style={styles.section}  >
                    <Text style={styles.titulo2} >I.     DATOS DEL SOLICITANTE</Text>
                </View>

                <View style={[styles.sectiontabla, styles.row, styles.bold]} >

                    <Text style={styles.row1} >ID de Empleado</Text>
                    <Text style={styles.row2} >Apellido Paterno</Text>
                    <Text style={styles.row2} >Apellido Materno</Text>
                    <Text style={styles.row3} >Nombre (s)</Text>
                </View>
                <View style={[styles.sectiondatos, styles.row, styles.bold]} >

                    <Text style={styles.row1} >{EMPLID}</Text>
                    <Text style={styles.row2} >{APATERNO}</Text>
                    <Text style={styles.row2} >{AMATERNO}</Text>
                    <Text style={styles.row3} >{NOMBRE}</Text>

                </View>

                <View style={[styles.sectiontabla, styles.row, styles.bold]} >

                    <Text style={styles.row4} >Campus</Text>
                    <Text style={styles.row5} >Dirección a la que petenece</Text>
                    <Text style={styles.row6} >Fecha de solicitud</Text>
                </View>

                <View style={[styles.sectiondatos, styles.row, styles.bold]} >

                    <Text style={styles.row4} >{CAMPUS}</Text>
                    <Text style={styles.row5} >{DEPARTAMENTO}</Text>
                    <Text style={styles.row6} >{moment(FECHA_DE_SOLICITUD).format('LLL')}</Text>
                </View>
                <View style={styles.section}  >
                    <Text style={styles.titulo2} >I.     DATOS DEL PERIODO VACACIONAL</Text>
                </View>


                <View style={[styles.sectiontabla, styles.row, styles.bold]} >

                    <Text style={styles.row7} >Los días comprenden las siguientes fechas:</Text>
                    <Text style={styles.row8} >Reanudando laboresl el día:</Text>
                </View>

                <View style={[styles.sectiondatos, styles.row, styles.bold]} >

                    <Text style={styles.row7} >Del {moment(FECHA_INICIAL).format('LL')} AL {moment(FECHA_FINAL).format('LL')}</Text>
                    <Text style={styles.row8} >{moment(FECHA_REANUDA).format('LL')}</Text>
                </View>








            </Page>
        </Document >
    )





};
export default FOCH11