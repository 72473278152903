import React from "react";
import moment from "moment";

const InfoUsuario = ({ empleado, dias_disponibles, dias_en_proceso }) => {
  return (
    <>
      <div className="row col-12 shadow rounded m-0 p-0 tablainfo ">
        <div className="row col-12 p-1 m-0 ">
          <div className="col-lg-6 col-md-6  col-sm-12 col-12   ">
            Nombre:{" "}
            {empleado.NOMBRE +
              " " +
              empleado.APATERNO +
              " " +
              empleado.AMATERNO}
          </div>
          <div className="col-lg-6 col-md-6  col-sm-12 col-12  ">
            Ingreso: {moment(empleado.FECHAINGRESO).format("DD/MM/yy")}
          </div>
          <div className="col-lg-6 col-md-6  col-sm-12 col-12 ">
            N.Empleado: {empleado.EMPLID}
          </div>
          <div className="col-lg-6 col-md-6  col-sm-12 col-12  ">
            Puesto: {empleado.PUESTO}
          </div>
          <div className="col-lg-6 col-md-6  col-sm-12 col-12 ">
            Días Disponibles: {dias_disponibles}
          </div>
          <div className="col-lg-6 col-md-6  col-sm-12 col-12  ">
            Días Solicitados: {dias_en_proceso}
          </div>



        </div>
      </div>
    </>
  );
};

export default InfoUsuario;
