import React, { useContext } from "react";
import EmpContext from "../../context/empleados/EmpContext";
import AdminSolicitudes from "../../subcomponents/AdminSolicitudes";
import "../../css/miequipo.css";
const Mi_equipo = () => {

  const { empleado, sol_personal, get_sol_personal, colaboradores, get_colaboradores, eventos, get_eventos, g_color, res_color, setEsol, admSol, res_esol } = useContext(EmpContext)

  return (
    <>
      <div className="row justify-content-center p-0 m-0 ">
        <div className="row   p-0 m-0 ">

          <div className="col-12 w-100 p-0 m-0">
            <AdminSolicitudes empleado={empleado} get_sol_personal={get_sol_personal} sol_personal={sol_personal} get_colaboradores={get_colaboradores} colaboradores={colaboradores} eventos={eventos} get_eventos={get_eventos} g_color={g_color} res_color={res_color} setEsol={setEsol} admSol={admSol} res_esol={res_esol} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Mi_equipo;
