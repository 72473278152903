import React, { useState, useEffect } from "react";
import { Pagination, Table } from "react-bootstrap";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";
import toast from "react-hot-toast";

import {
  Modal,
  Button,
  Row,
  Col,
  FormControl,
  FloatingLabel,
} from "react-bootstrap";


const SolicitudesEnProceso = ({ empleado, solicitudes, getSol, res_nsol, res_esol, admSol, getdias_en_proceso, getdias_disponibles, setEsol }) => {

  const [pagina, setPagina] = useState(1);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [sbtn, setSbtn] = useState(true);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const [folio, setFolio] = useState(0);
  const [status, setStatus] = useState(0);
  const [comentario, setComentario] = useState(0);
  const [fecha_inicial, setFecha_inicial] = useState(0);
  const [fecha_final, setFecha_final] = useState(0);
  const [fecha_reanuda, setFecha_reanuda] = useState(0);
  const [fecha_solicitud, setFecha_solicitud] = useState(0);
  const [dias_solicitados, setDias_solicitados] = useState(0);
  const [nombres, setNombres] = useState(0);
  const [apellido_paterno, setApellido_paterno] = useState(0);
  const [apellido_materno, setApellido_materno] = useState(0);
  const [motivo, setMotivo] = useState("");

  const Confirmar = () => {
    setShow(false);
    setShow1(true);
  };

  useEffect(() => {
    if (res_esol !== 2) {

      if (res_esol === 1) {
        toast.error("Solicitud cancelada con Exito");
        setShow1(false);
        getSol(empleado.EMPLID);
        getdias_disponibles(empleado.EMPLID)
        getdias_en_proceso(empleado.EMPLID)
        setEsol()
      }
      if (res_esol === 0) {
        toast.error("Error al Cancelar");
        setShow1(false);
        setEsol()
      }

    }

  }, [res_esol])



  const Cancelar = ({ folio, motivo }) => {
    setEsol()
    admSol(empleado.EMPLID, motivo, folio, "C")
  }

  const Paginar = (datos, pagina) => {

    return datos.slice((pagina - 1) * 5, pagina * 5);
  };

  const Paginamax = (datos) => {
    let pmax = Math.floor(datos.length / 5);
    let resto = datos.length % 5;

    if (resto > 0) {
      pmax = pmax + 1;
    }

    return pmax;
  };
  const handleShow = ({
    FOLIO,
    status,
    COMENTARIO,
    FECHA_INICIAL,
    FECHA_FINAL,
    FECHA_REANUDA,
    FECHA_DE_SOLICITUD,
    DIAS_SOLICITADOS,
    APATERNO,
    AMATERNO,
    NOMBRE,
    MOTIVO,
  }) => {

    setFolio(FOLIO);
    setStatus(status);
    setComentario(COMENTARIO);
    setFecha_inicial(FECHA_INICIAL);
    setFecha_final(FECHA_FINAL);
    setFecha_reanuda(FECHA_REANUDA);
    setFecha_solicitud(FECHA_DE_SOLICITUD);
    setDias_solicitados(DIAS_SOLICITADOS);
    setNombres(NOMBRE);
    setApellido_paterno(APATERNO);
    setApellido_materno(AMATERNO);
    setMotivo(MOTIVO);

    if (status === "Rechazado" || status === "Cancelado") {
      setSbtn(true);
    } else {
      setSbtn(false);
    }

    setShow(true);
  };


  return (
    <>
      <Table bordered hover>
        <thead>
          <tr>
            <th className="col-1">Folio</th>
            <th className="col-2">Estatus</th>
            <th className="col-9">Comentario</th>
          </tr>
        </thead>
        <tbody>
          {Paginar(solicitudes, pagina).map(
            ({
              FOLIO,
              status,
              COMENTARIO,
              FECHA_INICIAL,
              FECHA_FINAL,
              FECHA_REANUDA,
              FECHA_DE_SOLICITUD,
              DIAS_SOLICITADOS,
              NOMBRE,
              APATERNO,
              AMATERNO,
              MOTIVO,
            }) => {

              return (
                <tr
                  key={FOLIO}
                  onClick={() => {
                    handleShow({
                      FOLIO,
                      status,
                      COMENTARIO,
                      FECHA_INICIAL,
                      FECHA_FINAL,
                      FECHA_REANUDA,
                      FECHA_DE_SOLICITUD,
                      DIAS_SOLICITADOS,
                      APATERNO,
                      AMATERNO,
                      NOMBRE,
                      MOTIVO,
                    });
                  }}
                >
                  <td>{FOLIO}</td>
                  <td>{status}</td>
                  <td>{COMENTARIO}</td>
                </tr>
              );
            }
          )}
        </tbody>
      </Table>

      <Pagination>
        <Pagination.First onClick={() => setPagina(1)} />
        <Pagination.Prev
          onClick={() => setPagina(pagina > 1 ? pagina - 1 : 1)}
        />

        <Pagination.Item active>{pagina}</Pagination.Item>

        <Pagination.Next
          onClick={() =>
            setPagina(pagina < Paginamax(solicitudes) ? pagina + 1 : Paginamax(solicitudes))
          }
        />
        <Pagination.Last
          onClick={() => {
            setPagina(Paginamax(solicitudes));
          }}
        />
      </Pagination>

      <Modal
        centered
        className="rounded"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Header className="p-0 m-0">
          <Modal.Title className="text-center w-100 bg-primary text-white ">
            <Row>
              <Col className="col-10">Información de Solicitud</Col>
              <Col className="col-2">
                <Button
                  variant="transparent"
                  onClick={handleClose}
                  className="float-right"
                >
                  <i className="fa-solid fa-xmark"></i>
                </Button>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 m-0">
          <table class='p-0 m-0 w-100' >
            <tr class='p-0 m-0 '>
              <td class='p-1 m-0 '>
                <label> Folio</label>
                <input class='p-0 m-0 form-control text-center' type="text" value={folio} readOnly />
              </td>

              <td class='p-1 m-0 '>
                <label> Estatus</label>
                <input class='p-0 m-0 form-control text-center' type="text" value={status} readOnly />
              </td>
              <td class='p-1 m-0 '>
                <label> F.Solicitud</label>
                <input class='p-0 m-0 form-control text-center' type="text" value={moment(fecha_solicitud).format("DD/MM/yy")} readOnly />
              </td>

            </tr>
          </table>
          <table class='p-0 m-0 w-100' >

            <tr class='p-0 m-0 '>
              <td class='p-1 m-0 col-8 '>
                <label> Nombre</label>
                <input class='p-0 m-0 form-control text-center' type="text" value={nombres + " " + apellido_paterno + " " + apellido_materno} readOnly />
              </td>
              <td class='p-1 m-0 col-4 '>
                <label> Días solicitados</label>
                <input class='p-0 m-0 form-control text-center' type="text" value={dias_solicitados} readOnly />
              </td>

            </tr>

          </table>

          <table class='p-0 m-0 w-100' >
            <tr class='p-0 m-0 '>
              <td class='p-1 m-0 '>
                <label> Fecha Inicial</label>
                <input class='p-0 m-0 form-control text-center' type="text" value={moment(fecha_inicial).format("DD/MM/yy")} readOnly />
              </td>

              <td class='p-1 m-0 '>
                <label> Fecha Final</label>
                <input class='p-0 m-0 form-control text-center' type="text" value={moment(fecha_final).format("DD/MM/yy")} readOnly />
              </td>
              <td class='p-1 m-0 '>
                <label> Reanuda</label>
                <input class='p-0 m-0 form-control text-center' type="text" value={moment(fecha_reanuda).format("DD/MM/yy")} readOnly />
              </td>

            </tr>
            <tr>
              <td colSpan='3' class='p-1 m-0 '>
                <label> Comentario</label>
                <textarea class='p-1 m-0 form-control text-center' type="text" value={comentario} readOnly />
              </td>
            </tr>
            <tr hidden={!motivo && true}>
              <td colSpan='3' class='p-1 m-0 ' >
                <label> Motivo de rechazo o cancelación</label>
                <textarea class='p-1 m-0 form-control text-center' type="text" value={motivo} readOnly />
              </td>
            </tr>
          </table>


        </Modal.Body>
        <Modal.Footer className="m-0 p-0">
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>


    </>
  );

};

export default SolicitudesEnProceso;
