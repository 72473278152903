import React, { useContext, useEffect, useState } from "react";
import Barra from "./components/navegacion/Barra";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Mis_solicitudes from "./components/paginas/Mis_solicitudes";
import Contacto_ch from "./components/paginas/Contacto_ch";
import Mi_equipo from "./components/paginas/Mi_equipo.jsx";
import Saldo_liquidaciones from "./components/paginas/Saldo_liquidaciones";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { PageLayout } from "./components/PageLayout";
import EmpContext from "./context/empleados/EmpContext";
import { SignOutButton } from "./components/SignOutButton";
import { SignInButton } from "./components/SignInButton";


const App = () => {
  const { accounts } = useMsal();
  const [username, setUsername] = useState("");
  const [autorizado, setAutorizado] = useState(false);
  const { getEmp, empleado, get_contrato, contrato, g_empcont, res_gcont, g_persona, res_gper, g_directorio, directorio, g_zonas, g_campus, zonas, campus } = useContext(EmpContext);
  useEffect(() => {
    if (accounts.length > 0) {
      setUsername(accounts[0].username);
    }
  }, [accounts]);

  useEffect(() => {
    if (username !== "") {
      getEmp(username);
    }
  }, [username]);

  useEffect(() => {
    if (empleado.EMPLID) {
      empleado.EMAIL = username;

      if (res_gcont === 0) {
        g_empcont(empleado)
        g_persona(empleado)
      }
      get_contrato(empleado.EMPLID)
      if (empleado.ESTADOCONTRATO === "Activo") {
        setAutorizado(true);
      } else {
        setAutorizado(false);
      }
    }
  }, [empleado]);

  const { instance } = useMsal();

  function handleLogout(instance) {
    const logoutRequest = {
      account: instance.getAccountByHomeId('273875bf-36f5-466b-be95-c66a92618eeb'),
      postLogoutRedirectUri: "/"
    }
    console.log(instance.logout());

  }


  if (username) {
    if (autorizado) {
      return (
        <>
          <Router>
            <Barra />
            <div className="align-conten-center  p-0 m-lg-5 m-md-5 m-sm-5 m-3 mt-5">
              <div className="row pt-5 mt-5">
                <Routes>
                  <Route
                    path="/mis_solicitudes/"
                    element={<Mis_solicitudes empleado={empleado} res_gcont={res_gcont} res_gper={res_gper} />}
                  />
                  <Route path="/mi_equipo/" element={<Mi_equipo />} />
                  <Route path="/" element={<Mis_solicitudes empleado={empleado} res_gcont={res_gcont} res_gper={res_gper} />} />
                  <Route path="/contacto_ch" element={<Contacto_ch empleado={empleado} g_directorio={g_directorio} directorio={directorio} g_campus={g_campus} campus={campus} g_zonas={g_zonas} zonas={zonas} />} />
                  <Route
                    path="/saldo_liquidaciones"
                    element={<Saldo_liquidaciones />}
                  />
                </Routes>
              </div>
            </div>
          </Router>
        </>
      );
    } else {

      if (!empleado) {

        return (
          <>
            <div className="m-0 p-0 vh-100  row justify-content-center align-content-center ">
              <div className="card bg-light text-dark          w-auto   ">
                <img
                  src="Aliat-Universidades.png"
                  className="card-img-top"
                  alt="..."
                ></img>
                <div className="card-body w-100">
                  <p className="card-text text-center">
                    <b> {username} </b> <br /> No tiene acceso al sistema.

                  </p>
                  <Router> <Link className="btn btn-primary w-100 " to='/' onClick={() => handleLogout(instance)}>Cerrar Sesión</Link></Router>

                </div>
              </div>
            </div>
          </>
        );



      } else {

        return (
          <>

            <div className="m-0 p-0 vh-100  row justify-content-center align-content-center ">
              <div className=" w-auto  ">

                <div >

                  <div class="spinner-grow text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow text-danger" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow text-warning" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow text-info" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow text-light" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>

                </div>
              </div>
            </div>






          </>
        );




      }


    }
  } else {
    return (
      <>
        <UnauthenticatedTemplate>
          <div className="m-0 p-0 vh-100  row justify-content-center align-content-center ">
            <div className="card bg-light text-dark       w-auto       ">
              <img
                src="Aliat-Universidades.png"
                className="card-img-top"
                alt="..."
              ></img>
              <div className="card-body">


                <ul className="navbar-nav  ms-auto">
                  <li className="nav-item ">
                    <SignInButton />
                  </li>
                </ul>

              </div>
            </div>
          </div>
        </UnauthenticatedTemplate>
      </>
    );
  }
};

export default App;

