import React from "react";
import { useMsal } from "@azure/msal-react";

import { Link } from 'react-router-dom'
function handleLogout(instance) {


    const logoutRequest = {
        account: instance.getAccountByHomeId('273875bf-36f5-466b-be95-c66a92618eeb'),
        postLogoutRedirectUri: "/"
    }
    instance.logoutRedirect(logoutRequest);


    // instance.logout().catch(e => {
    //     console.error(e);
    // });
}

/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const SignOutButton = () => {
    const { instance } = useMsal();
    return (
        <Link className="nav-link bg-transparent m-0 p-0" to='/' onClick={() => handleLogout(instance)}><i class="fa-solid fa-power-off fa-2x"></i></Link>
    );
}