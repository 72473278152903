import React, { useEffect } from 'react'
import "../../css/contactoch.css"

const Contacto_ch = ({ empleado, g_directorio, directorio, g_zonas, g_campus, zonas, campus }) => {

    useEffect(() => {
        g_directorio(empleado.CAMPUS)
        g_zonas()

    }, [empleado])

    const filtrarz = (e) => {
        g_directorio(e)
        g_campus(e)
        const filtrarz = (e) => {
            g_directorio(e)
            g_campus(e)
            console.log(campus);
        }
    }
    const filtrarc = (e) => {
        g_directorio(e)

    }

    return (
        <>

            <div className=" row  col-12 d-flex justify-content-end p-0 m-0">
                <div className="col-lg-3  col-md-12 col-sm-12 col-12">
                    <select className="form-control custom-select my-1 mr-sm-2" onChange={(e) => filtrarz(e.target.value)}>
                        <option selected disabled>Selecciona Zona</option>
                        {zonas.map(
                            ({
                                zona
                            }) => {

                                return (

                                    <option key={zona} value={zona}>{zona}</option>

                                )
                            })
                        }

                    </select>
                </div>
                <div className="col-lg-3  col-md-12 col-sm-12 col-12">
                    <select className="form-control custom-select my-1 mr-sm-2" onChange={(e) => filtrarc(e.target.value)}>
                        <option selected disabled>Selecciona Campus</option>
                        {campus.map(
                            ({
                                campus
                            }) => {

                                return (

                                    <option key={campus} value={campus}>{campus}</option>

                                )
                            })
                        }
                    </select>
                </div>

            </div>

            <div className=" row  col-12 d-flex justify-content-center p-0 m-0">


                {directorio.map(
                    ({
                        zona,
                        campus,
                        nombre,
                        puesto,
                        email,
                        telefono,
                        extension,
                    }) => {

                        return (
                            <div className="col-12 col-sm-12 col-md-6 col-lg-12  p-2 m-0 " >
                                <div className="   card" style={{ borderRadius: '15px' }} >
                                    <div className="card-body  p-1 m-0 col-12">
                                        <div className="d-flex text-black  row  p-0 m-0">
                                            <div className="col-lg-1 col-md-3 col-sm-3 col-3  d-flex justify-content-center   p-0 m-0">
                                                <img src="..\img\no-avatar.jpeg"
                                                    className="img-fluid rounded"

                                                    style={{ width: '4rem', height: '4rem' }}

                                                />
                                            </div>
                                            <div className="col-lg-11 col-md-9 col-sm-9 col-9 row   p-0 m-0 ">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 p-0 m-0 text-muted  ">
                                                    {zona}
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 p-0 m-0 text-muted " >
                                                    {campus}
                                                </div>

                                                <div className="col-12 col-sm-12 col-md-12 col-lg-3 p-0 m-0 d-none d-sm-none d-md-none d-lg-block ">
                                                    <h5 className="card-title">{nombre}</h5>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-3 p-0 m-0  text-muted d-none d-sm-none d-md-none d-lg-block   ">
                                                    {puesto}
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 p-0 m-0  text-muted d-none d-sm-none d-md-none d-lg-block   ">
                                                    {email} <a href={"mailto:" + email} alt='Enviar Correo' > <i class="fa-solid fa-envelope "></i></a>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-2 p-0 m-0  text-muted d-none d-sm-none d-md-none d-lg-block   ">
                                                    {telefono} <a href={`tel:${telefono}`} alt='Llamar'><i class="fa-solid fa-phone"></i></a> {extension && `Ext:${extension}`}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body  p-1 m-0 col-12  d-block d-sm-block d-md-block d-lg-none ">
                                        <div className="col-lg-12   p-0 m-0 ">

                                            <div className="col-12 col-sm-12 col-md-12 col-lg-3 p-0 m-0 d-block d-sm-block d-md-block d-lg-none ">
                                                <h5 className="card-title">{nombre}</h5>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-3 p-0 m-0  text-muted d-block d-sm-block d-md-block d-lg-none   ">
                                                {puesto}
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-4 p-0 m-0  text-muted d-block d-sm-block d-md-block d-lg-none   ">
                                                {email} <a href={"mailto:" + email} alt='Enviar Correo' > <i class="fa-solid fa-envelope "></i></a>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-2 p-0 m-0  text-muted d-block d-sm-block d-md-block d-lg-none   ">
                                                {telefono} <a href={`tel:${telefono}`} alt='Llamar'><i class="fa-solid fa-phone"></i></a> {extension && `Ext:${extension}`}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        )
                    })}















            </div >

        </>
    )
}

export default Contacto_ch