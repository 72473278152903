import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import Formato from "../subcomponents/Formato";
import toast, { Toaster } from "react-hot-toast";

import {
  Modal,
  Button,
  Row,
  Col,

  Form,
} from "react-bootstrap";
import { differenceInCalendarDays } from "date-fns";




const Solicitud = ({ empleado, dias_disponibles, dias_en_proceso, getSol, NuevaSolicitud, dias_festivos, res_nsol, solicitudes, getdias_disponibles, getdias_en_proceso }) => {




  const [labora, setLabora] = useState(['lun.', 'mar.', 'mié.', 'jue.', 'vie.', 'sáb.', 'dom.'])
  const [f_i, setf_i] = useState(moment().format("yyyy-MM-DD"))
  const [f_f, setf_f] = useState(moment().format("yyyy-MM-DD"))
  const [checkedUno, setcheckedUno] = useState(true)
  const [checkedDos, setcheckedDos] = useState(true)
  const [checkedTres, setcheckedTres] = useState(true)
  const [sModal, setsModal] = useState(false)
  const [sRango, setsRango] = useState("")
  const [disabledDates, setdisabledDates] = useState(dias_festivos)
  const [regresa, setregresa] = useState(moment().format("yyyy-MM-DD"))
  const [solicitados, setsolicitados] = useState(0)
  const [comentario, setcomentario] = useState('')
  const [sFormato, setsFormato] = useState(false)

  disabledDates.push(moment()["_d"])

  const tileDisabled = ({ date, view }) => {
    if (view === "month") {
      return disabledDates.find((dDate) => isSameDay(dDate, date));
    }
  }
  const isSameDay = (a, b) => {
    return differenceInCalendarDays(a, b) === 0;
  }

  const cambio = (e) => {
    setcomentario(e.target.value)
  }

  const rango = (e) => {
    if (e.length === 2) {
      setsRango(e)
      setf_i(moment(e[0]).format("yyyy-MM-DD"))
      setf_f(moment(e[1]).format("yyyy-MM-DD"))
    }

    var dias = [];

    var lafecha = moment(e[0]).format("yyyy-MM-DD");
    do {
      var eldia = moment(lafecha).format("ddd");

      if (
        labora.find((element) => element === eldia) &&
        !disabledDates.find(
          (element) => element === moment(lafecha).format("yyyy-MM-DD")
        )
      )
        dias.push(moment(lafecha).format("yyyy-MM-DD"));

      lafecha = moment(lafecha).add(1, "d");
    } while (
      moment(lafecha).format("yyyy-MM-DD") <= moment(e[1]).format("yyyy-MM-DD")
    );

    var regs = moment(e[1]).format("yyyy-MM-DD");
    var respuesta = true;

    do {
      regs = moment(regs).add(1, "d");
      var dregs = moment(regs).format("ddd");

      if (
        labora.find((element) => element === dregs) &&
        !disabledDates.find(
          (element) => element === moment(regs).format("yyyy-MM-DD")
        )
      ) {
        setregresa(moment(regs).format("yyyy-MM-DD"))
        respuesta = false;
      }
    } while (respuesta);
    console.log(regresa)

    if (dias.length <= (dias_disponibles - dias_en_proceso)) {

      setsolicitados(dias.length)
      console.log(dias)

    } else {
      toast.error("Los días seleccionados son mas que los disponibles");

      setf_i(moment().format("yyyy-MM-DD"))
      setf_f(moment().format("yyyy-MM-DD"))
      setsRango("")
      setsolicitados(0)

    }
  };


  const showFormato = () => {

    if (
      solicitados <= (dias_disponibles - dias_en_proceso) &&
      solicitados > 0 &&
      (dias_disponibles - dias_en_proceso) > 0
    ) {
      setsFormato(!sFormato);
    } else {
      toast.error("Selecciona Rango de Fechas");

      setf_i(moment().format("yyyy-MM-DD"))
      setf_f(moment().format("yyyy-MM-DD"))
      setsRango("")
      setsolicitados(0)

    }
  };


  const cc1 = (e) => {
    var lista = labora
    if (!checkedUno) {
      lista.push("lun.");
      lista.push("mar.");
      lista.push("mié.");
      lista.push("jue.");
      lista.push("vie.");
      calcular(lista)
      setcheckedUno(!checkedUno)

    } else {
      lista = lista.filter(function (f) { return f !== 'lun.' })
      lista = lista.filter(function (f) { return f !== 'mar.' })
      lista = lista.filter(function (f) { return f !== 'mié.' })
      lista = lista.filter(function (f) { return f !== 'jue.' })
      lista = lista.filter(function (f) { return f !== 'vie.' })
      calcular(lista)
      setcheckedUno(!checkedUno)

    }

  }
  const cc2 = (e) => {
    var lista = labora
    if (!checkedDos) {
      lista.push("sáb.");
      calcular(lista)
      setcheckedDos(!checkedDos)

    } else {
      lista = lista.filter(function (f) { return f !== 'sáb.' })
      calcular(lista)
      setcheckedDos(!checkedDos)

    }


  }
  const cc3 = (e) => {
    var lista = labora
    if (!checkedTres) {
      lista.push("dom.");
      calcular(lista)
      setcheckedTres(!checkedTres)

    } else {
      lista = lista.filter(function (f) { return f !== 'dom.' })
      calcular(lista)
      setcheckedTres(!checkedTres)

    }

  }

  const calcular = (lista) => {
    setLabora(lista)
    var dias = [];

    var lafecha = moment(f_i).format("yyyy-MM-DD");
    do {
      var eldia = moment(lafecha).format("ddd");

      if (
        lista.find((element) => element === eldia) &&
        !disabledDates.find(
          (element) => element === moment(lafecha).format("yyyy-MM-DD")
        )
      )
        dias.push(moment(lafecha).format("yyyy-MM-DD"));

      lafecha = moment(lafecha).add(1, "d");
    } while (
      moment(lafecha).format("yyyy-MM-DD") <= moment(f_f).format("yyyy-MM-DD")
    );

    var regs = moment(f_f).format("yyyy-MM-DD");
    var respuesta = true;
    do {
      regs = moment(regs).add(1, "d");
      var dregs = moment(regs).format("ddd");

      if (
        lista.find((element) => element === dregs) &&
        !disabledDates.find(
          (element) => element === moment(regs).format("yyyy-MM-DD")
        )
      ) {

        setregresa(moment(regs).format("yyyy-MM-DD"))
        respuesta = false;
      }
    } while (respuesta);

    if (dias.length <= (dias_disponibles - dias_en_proceso)) {

      setsolicitados(dias.length)
      console.log(dias)

    } else {
      toast.error("Los días seleccionados son mas que los disponibles");
      setf_i(moment().format("yyyy-MM-DD"))
      setf_f(moment().format("yyyy-MM-DD"))
      setsRango('')
      setsolicitados(0)

    }


  };



  return (
    <>
      <Toaster
        containerStyle={{
          position: "fixed",
          top: "40%",
        }}
        toastOptions={{
          success: {
            style: {
              background: "green",
            },
          },
          error: {
            style: {
              background: "red",

              color: "white",
            },
          },
        }}
      />
      <div className="col-lg-12 ">
        <button
          type="button"
          className="btn btn-block btn-primary  btn-lg w-100 fw-bolder "
          onClick={() => {
            setsModal(!sModal)
            setf_i(moment().format("yyyy-MM-DD"))
            setf_f(moment().format("yyyy-MM-DD"))
            setsRango("")
            setsolicitados(0)
          }}
        >
          + Solicitar Vacaciones
        </button>
      </div>

      <Modal
        centered
        show={sModal}
        onHide={() => setsModal(!sModal)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="text-center w-100 bg-dark text-white p-0 m-0 ">
          <Modal.Title className="text-center w-100 bg-primary text-white p-0 m-0 ">
            Solicitud de vacaciones
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="col-12 p-0 m-0">
              <Calendar
                className="w-100"
                locale="es-ES"
                minDate={moment()["_d"]}
                selectRange={true}
                view="month"
                onChange={rango}
                value={sRango}
                tileDisabled={tileDisabled}
              />
            </Col>

            <Col className="col-lg-6 col-md-6 col-sm-6 col-6 p-1 m-0">
              <label>Fecha inicial</label>
              <input type="text"
                class='form-control  p-0 m-0 '
                id="f_i"
                name="f_i"
                value={moment(f_i).format("LL")} readOnly
                style={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}
              />

            </Col>
            <Col className="col-lg-6 col-md-6 col-sm-6 col-6 p-1 m-0">

              <label>Fecha final</label>
              <input type="text"
                class='form-control  p-0 m-0  '
                id="f_f"
                name="f_f"
                value={moment(f_f).format("LL")} readOnly
                style={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}

              />


            </Col>
            <Col className="col-lg-6 col-md-6 col-sm-6 col-6 p-1 m-0">

              <label>Fecha final</label>


              <input type="text"
                class='form-control text-lg p-0 m-0 '
                id="regresa"
                name="regresa"
                value={moment(regresa).format("LL")} readOnly
                style={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}
              />
            </Col>
            <Col className="col-3 p-1 m-0">


              <label>Disponibles</label>
              <input type="number"
                class='form-control text-lg p-0 m-0 '
                id="dias_disponibles"
                name="dias_disponibles"
                value={(dias_disponibles - dias_en_proceso)}
                style={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}
                readOnly

              />



            </Col>
            <Col className="col-3 p-1 m-0">
              <label>Solicitados</label>
              <input type="number"
                class='form-control text-lg p-0 m-0'
                name="solicitados"
                id="solicitados"
                value={solicitados}
                style={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}
                readOnly />

            </Col>
            <Col className="col-12 p-1 m-0">
              <label>Comentarios</label>
              <textarea
                class='form-control text-lg p-0 m-0  text-center'
                name="comentario"
                id="comentario"
                rows="3"
                value={comentario}
                onChange={cambio}
              ></textarea>

            </Col>
            <Col className="col-12  p-0 m-0 ">
              <div className="row col-12 align-content-center justify-content-center p-4 pt-0 pb-0">
                <label className="font-weight-bold text-center w-100">
                  ¿Qué días labora?
                </label>
                <div className="col-4 p-0 m-0">
                  <Form.Check
                    type="checkbox"
                    id="checkedUno"
                    name="checkedUno"
                    label="L-V"
                    checked={checkedUno}
                    onChange={cc1}
                  />
                </div>
                <div className="col-4  p-0 m-0">
                  <Form.Check
                    type="checkbox"
                    id="checkedDos"
                    name="checkedDos"
                    label="Sab"
                    checked={checkedDos}
                    onChange={cc2}
                  />
                </div>
                <div className="col-4 p-0 m-0">
                  <Form.Check
                    type="checkbox"
                    id="checkedTres"
                    name="checkedTres"
                    label="Dom"
                    checked={checkedTres}
                    onChange={cc3}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="p-0 m-0">
          <Button variant="secondary" onClick={() => setsModal(!sModal)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={() => { if (solicitados > 0) { setsFormato(!sFormato) } else { toast.error("Selecciona un rango") } }}>
            Generar
          </Button>
        </Modal.Footer>
      </Modal>



      <Modal centered show={sFormato} onHide={() => showFormato()} size="lg">
        <Modal.Header className="text-center w-100 bg-dark text-white p-1 m-0 " >
          <Modal.Title className="text-center w-100 bg-dark text-white ">
            Formato
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formato toast={toast} getdias_disponibles={getdias_disponibles} getdias_en_proceso={getdias_en_proceso} empleado={empleado} fecha_solicitud={moment().format("yyyy-MM-DD")} f_f={f_f} f_i={f_i} regresa={regresa} NuevaSolicitud={NuevaSolicitud} solicitudes={solicitudes} comentario={comentario} getSol={getSol} res_nsol={res_nsol} solicitados={solicitados} showFormato={showFormato} setsModal={setsModal} />
        </Modal.Body>

      </Modal>


    </>
  )
}

export default Solicitud


