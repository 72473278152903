import React, { useEffect, useState } from "react";
import InfoUsuario from "../../subcomponents/InfoUsuario";
import Solicitud from "../Solicitud";
import SolicitudesEnProceso from "../../subcomponents/SolicitudesEnProceso";
import Resumen from "../../subcomponents/Resumen";
import { useContext } from "react";
import EmpContext from "../../context/empleados/EmpContext";
import { useMsal } from "@azure/msal-react";
import moment from "moment";

import "../../css/missolicitudes.css";



const Mis_solicitudes = () => {
  const { accounts } = useMsal();
  const username = accounts[0].username;
  const { getEmp, getSol, empleado, solicitudes, getdias_disponibles, getdias_en_proceso, dias_disponibles, dias_en_proceso, NuevaSolicitud, getdias_festivos, dias_festivos, res_nsol, admSol, res_esol, setEsol, info_sol, insolicitud } = useContext(EmpContext)
  const [datosCargados, setDatosCargados] = useState(false)
  const [autorizado, setAutorizado] = useState(false)


  useEffect(() => {
    getEmp(username)
  }, [username])


  useEffect(() => {

    if (empleado.EMPLID) {

      if (empleado.ESTADOCONTRATO === "Activo") {
        getSol(empleado.EMPLID)
        getdias_disponibles(empleado.EMPLID)
        getdias_en_proceso(empleado.EMPLID)
        setDatosCargados(true)
        getdias_festivos(moment().format("yyyy"))
        setAutorizado(true)

      } else {
        setDatosCargados(true)
        setAutorizado(false)

      }



    }

  }, [empleado])




  if (datosCargados) {

    if (autorizado) {

      return (
        <>
          <div className="row justify-content-center p-1 m-0 ">
            <div className="col-12 col-sm-12 col-md-8 col-lg-8  p-0 m-0">
              <InfoUsuario empleado={empleado} dias_disponibles={dias_disponibles} dias_en_proceso={dias_en_proceso} />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="row col-lg-12 col-md-12 col-sm-12 col-12   justify-content-center  m-0 p-4">
                <Solicitud empleado={empleado} getdias_disponibles={getdias_disponibles} getdias_en_proceso={getdias_en_proceso} dias_disponibles={dias_disponibles} dias_en_proceso={dias_en_proceso} getSol={getSol} NuevaSolicitud={NuevaSolicitud} res_nsol={res_nsol} dias_festivos={dias_festivos} solicitudes={solicitudes} />
              </div>
            </div>
          </div>

          <Resumen dias_disponibles={dias_disponibles} dias_en_proceso={dias_en_proceso} solicitudes={solicitudes} res_nsol={res_nsol} info_sol={info_sol} insolicitud={insolicitud} />

          <div className="table-responsive col-lg-12 col-md-12 col-sm-12 cil-12 shadow p-2 mt-2 justify-content-center  ">
            <SolicitudesEnProceso empleado={empleado} solicitudes={solicitudes} getSol={getSol} NuevaSolicitud={NuevaSolicitud} getdias_disponibles={getdias_disponibles} getdias_en_proceso={getdias_en_proceso} res_nsol={res_nsol} admSol={admSol} res_esol={res_esol} setEsol={setEsol} />
          </div>



        </>
      );



    } else {
      return (
        <>
          <div className="m-0 p-0 vh-100  row justify-content-center align-content-center ">
            <div className="card bg-light text-dark       w-auto       ">
              <img
                src="Aliat-Universidades.png"
                className="card-img-top"
                alt="..."
              ></img>
              <div className="card-body">
                <p className="card-text"> Tu cuenta no tiene acceso al sistema. </p>

              </div>
            </div>
          </div>
        </>
      );




    }


  } else {
    return (
      <>
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Cargando...</span>
          </div>
        </div>
      </>
    );
  }
}

export default Mis_solicitudes

