import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import { SignOutButton } from "../SignOutButton";
import { Nav, Navbar } from "react-bootstrap";
import "../../css/barra.css";



const Barra = () => {

  const [menu1, setmenu1] = useState('activo')
  const [menu2, setmenu2] = useState('')
  const [menu3, setmenu3] = useState('')
  const [menu4, setmenu4] = useState('')
  const [seccion, setseccion] = useState('Mis Solicitudes')
  const bactivo = (boton) => {

    if (boton === 'menu1') {
      setmenu1('activo')
      setseccion('Mis Solicitudes')
    } else {
      setmenu1('')
    }
    if (boton === 'menu2') {
      setmenu2('activo')
      setseccion('Mi Equipo')
    } else {
      setmenu2('')
    }
    if (boton === 'menu3') {
      setmenu3('activo')
      setseccion('Contacto CH')
    } else {
      setmenu3('')
    }
    if (boton === 'menu4') {
      setmenu4('activo')
      setseccion('Saldo Liquidaciones')
    } else {
      setmenu4('')
    }
  }

  return (
    <>

      <Navbar collapseOnSelect fixed="top" expand="lg" bg="white" variant="light" border='dark' className='border'>

        <Navbar.Brand href="#" className="logo"><img src="Logo-Aliat.webp" width="200" alt=""></img></Navbar.Brand><b className='d-block d-sm-block d-md-block d-lg-none'>{seccion}</b>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">

            <Nav.Link href="#"  >
              <NavLink
                to="mis_solicitudes"
                className={({ isActive }) => (isActive ? 'nav-link activo p-1 m-1' : ' nav-link inactivo')} id='menu1' onClick={(e) => bactivo(e.target.id)}
              >
                Mis Solicitudes
              </NavLink>
            </Nav.Link>

            <Nav.Link href="#" >

              <NavLink
                to="mi_equipo"
                className={({ isActive }) => (isActive ? 'nav-link activo  p-1 m-1' : ' nav-link inactivo')} id='menu2' onClick={(e) => bactivo(e.target.id)}
              >
                Mi Equipo
              </NavLink>

            </Nav.Link>
            <Nav.Link href="#" >
              <NavLink
                to="contacto_ch"
                className={({ isActive }) => (isActive ? 'nav-link activo  p-1 m-1' : ' nav-link inactivo')} id='menu3' onClick={(e) => bactivo(e.target.id)}
              >
                Contacto CH
              </NavLink>

            </Nav.Link>
            <Nav.Link href="#" >
              <NavLink
                to="saldo_liquidaciones"
                className={({ isActive }) => (isActive ? 'nav-link activo  p-1 m-1' : ' nav-link inactivo')} id='menu3' onClick={(e) => bactivo(e.target.id)}
              >
                Saldo liquidaciones
              </NavLink>
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link href="#" >
              <SignOutButton />

            </Nav.Link>
          </Nav>
        </Navbar.Collapse>

      </Navbar>
    </>
  );
};

export default Barra;
