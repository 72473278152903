export const msalConfig = {
  auth: {
    // clientId: "273875bf-36f5-466b-be95-c66a92618eeb",
    clientId: "c0368419-5894-4064-a043-09e33b30685d", //prod2
    authority: "https://login.microsoftonline.com/common",
    redirectUri: "https://appsch.redaliat.mx/mis_solicitudes", //prod
    // redirectUri: "http://localhost:3000/Mis_solicitudes",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
