import React, { useState } from "react";
import "./formato.css";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";

const Formato = ({
  toast,
  empleado,
  fecha_solicitud,
  f_f,
  f_i,
  regresa,
  NuevaSolicitud,
  solicitados,
  comentario,
  getSol,
  res_nsol,
  showFormato,
  getdias_disponibles,
  getdias_en_proceso,
  setsModal,
}) => {
  const [aceptar, setAceptar] = useState(false);
  const CrearSolicitud = async () => {
    await NuevaSolicitud({ empleado, f_i, f_f, regresa, solicitados, comentario });

    showFormato();



    toast.success("Solicitud Generada con Exito!")

    getSol(empleado.EMPLID);
    getdias_disponibles(empleado.EMPLID)
    getdias_en_proceso(empleado.EMPLID)
    setsModal(false)




  };

  return (
    <>
      <div className="container row text-left">
        <div className="col-12">
          <img src="Logo-Aliat.webp" width="50%" alt="" align="left"></img>
        </div>
        <div className="col-12 text-center">
          <h5> Solicitud de vacaciones</h5>

          <h5>Dirección Institusional de Capital Humano</h5>
          <h5>FO-CH-11</h5>
        </div>
        <br />

        <h4 className="text-left">I. DATOS DEL SOLICITANTE</h4>
        <br />
        <div className="row p-0 m-0">
          <div className="col-3 border border-secondary  titulo">
            ID de Empleado
          </div>
          <div className="col-9  row border border-secondary p-0 m-0  titulo">
            <div className="col-4 "> Apellido Paterno</div>
            <div className="col-4 "> Apellido Materno</div>
            <div className="col-4 "> Nombre(s)</div>
          </div>
          <div className="col-3 border border-secondary">
            {" "}
            {empleado.EMPLID}
          </div>
          <div className="col-9  row border border-secondary p-0 m-0">
            <div className="col-4">{empleado.APATERNO}</div>
            <div className="col-4"> {empleado.AMATERNO}</div>
            <div className="col-4"> {empleado.NOMBRE}</div>
          </div>

          <div className="col-4 border border-secondary  titulo">Campus</div>
          <div className="col-5  border border-secondary  titulo">
            Dirección a la que pertenece
          </div>
          <div className="col-3  border border-secondary  titulo">
            Fecha de solicitud
          </div>
          <div className="col-4 border border-secondary">{empleado.CAMPUS}</div>
          <div className="col-5  border border-secondary">
            {empleado.DEPARTAMENTO}
          </div>
          <div className="col-3  border border-secondary">
            {moment(fecha_solicitud).format("DD/MM/yyyy")}
          </div>
        </div>
        <br />
        <br />
        <h3 className="text-left">I. DATOS DEL PERIODO VACACIONAL</h3>
        <br />
        <div className="row p-0 m-0">
          <div className="col-7  border border-secondary  titulo">
            Los días comprenden las siguientes fechas:
          </div>
          <div className="col-5  border border-secondary  titulo">
            Reanudando labores el día:
          </div>
          <div className="row col-7  border border-secondary  p-0 m-0">
            <div className="col-5 p-0 m-0">
              {moment(f_i).format("DD/MM/yyyy")}
            </div>
            <div className="col-2 p-0 m-0">AL</div>
            <div className="col-5 p-0 m-0">
              {moment(f_f).format("DD/MM/yyyy")}
            </div>
          </div>
          <div className="col-5  border border-secondary">
            {moment(regresa).format("DD/MM/yyyy")}
          </div>
        </div>

        <div className="col-12 row p-0 m-0">
          <div className="col-1 p-0 m-0">
            <input
              type="checkbox"
              className="form-check-input p-2"
              value={aceptar}
              onChange={() => {
                setAceptar(!aceptar);
              }}
            />
          </div>
          <div className="col-11 p-0 m-0">
            Acepto términos y condiciones:
            <button className=" btn-link bg-transparent border-0" type="button">
              Términos de Uso del sitio
            </button>
            .Conozco los términos y alcance de la politica PO-CH-11 "Politica de
            Vacaciones", así como la Ley Federal de Trabajo y estoy de acuerdo y
            conforme en que se apliquen dichas condiciones.
          </div>
        </div>

        <Modal.Footer>
          <Button
            variant="success"
            disabled={!aceptar}
            onClick={() => CrearSolicitud()}
          >
            Confirmar
          </Button>
          <Button variant="danger" onClick={() => showFormato()}>
            Cancelar
          </Button>
        </Modal.Footer>
      </div>
    </>
  );
};

export default Formato;
