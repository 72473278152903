import React from "react";
import { Table } from "react-bootstrap";
import { PDFDownloadLink } from '@react-pdf/renderer';
import FOCH11 from "../subcomponents/FOCH11"
const Resumen = ({ solicitudes, dias_disponibles, dias_en_proceso, info_sol, insolicitud }) => {
  return (
    <>
      <div className="table-responsive col-lg-10 col-md-10 col-sm-12 cil-12 shadow p-2 mt-2 justify-content-center rounded  h-50 ">


        <Table bordered responsive hover >
          <thead>
            <tr>
              <th>Periodo</th>
              <th>Estado</th>
              <th>Formato</th>
            </tr>
          </thead>
          <tbody>
            {solicitudes.map(({ EMPLID, FECHA_INICIAL, FECHA_FINAL, status, FOLIO, APATERNO, AMATERNO, NOMBRE, CAMPUS, DEPARTAMENTO, FECHA_DE_SOLICITUD, FECHA_REANUDA }) => {
              if (status === "Autorizado") {
                return (
                  <tr key={FOLIO}>
                    <td>
                      De {FECHA_INICIAL} a {FECHA_FINAL}
                    </td>
                    <td>{status}</td>
                    <td>    <PDFDownloadLink document={<FOCH11 EMPLID={EMPLID} FECHA_INICIAL={FECHA_INICIAL} FECHA_FINAL={FECHA_FINAL} APATERNO={APATERNO} AMATERNO={AMATERNO} NOMBRE={NOMBRE} CAMPUS={CAMPUS} DEPARTAMENTO={DEPARTAMENTO} FECHA_DE_SOLICITUD={FECHA_DE_SOLICITUD} FECHA_REANUDA={FECHA_REANUDA} />} fileName={FOLIO + ".pdf"} >
                      {({ blob, url, loading, error }) =>
                        loading ? 'Cargando...' : 'Descargar'
                      }
                    </PDFDownloadLink></td>
                  </tr>
                );
              }
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default Resumen;
